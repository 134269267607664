import React from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import styled from 'styled-components'
import Loader from '../UI/Loader'
import Writing from '../UI/Writing'
import EmptyFeed from './EmptyFeed'
import useGlobalFeed from './useGlobalFeed'

const Wrapper = styled.div`
  margin-top: 50px;
  > * {
    height: 100%;
  }
  > * > * {
    margin-bottom: 25px;
  }
  max-width: 900px;
  @media only screen and (max-width: 1024px) {
    max-width: 90%;
  }
  font-family: 'Rammetto One', cursive;
`

const GlobalFeed = () => {
  const { writings, loadMore, hasMore } = useGlobalFeed()

  if (writings.length === 0 && !hasMore)
    return (
      <Wrapper>
        <EmptyFeed />
      </Wrapper>
    )
  return (
    <Wrapper>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMore}
        loader={<Loader key="loader" color='#fff' placeholder='Loading new writing...' />}
      >
        {writings.map((w) => (
          <Writing key={w._id} {...w} />
        ))}
      </InfiniteScroll>
    </Wrapper>
  )
}

GlobalFeed.propTypes = {}

GlobalFeed.defaultProps = {}

export default GlobalFeed
