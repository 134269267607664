import React from "react"
import Layout from '../layouts/layout'
import WritingsPage from "../components/pages/WritingsPage"

const WritingPageWrapper = () => {
  if (typeof window === "undefined") return null

  return <Layout>
    <WritingsPage />
  </Layout>
}

export default WritingPageWrapper
