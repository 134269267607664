import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
  color: white;
  text-align: center;
`

const EmptyFeed = props => {
  return (
    <Wrapper>
      <div>Here, you will be able to see all of your children's writing</div>
    </Wrapper>
  )
}

EmptyFeed.propTypes = {}

EmptyFeed.defaultProps = {}

export default EmptyFeed
