import React from 'react'
import styled from 'styled-components'
import { AccountPageState } from '../../../layouts/AccountPagesLayout/index.state'
import GlobalWritings from '../../GlobalFeed'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Rammetto One', cursive;
  margin-top: 35px;
`

const Title = styled.div`
  font-size: clamp(12pt, 26pt, 26pt);
  align-items: center;
  color: #FFFFFF;
  text-shadow: 0px 5px #afafaf, 0px 15px rgba(0, 0, 0, 0.3);
  white-space: nowrap;
  font-family: 'Rammetto One';
`

const WritingsPage = () => {
  return <Wrapper>
    <Title>Writing Feed</Title>
    <GlobalWritings />
  </Wrapper>
}


export default (props) =>
  <AccountPageState.Provider>
    <WritingsPage {...props} />
  </AccountPageState.Provider>

