import { useApolloClient } from '@apollo/client'
import { useState } from "react"
import GET_CONTENT from "./getContent.graphql"

const NB_ITEMS = 3

export default () => {
  const [writings, setWritings] = useState([])
  const [hasMore, setHasMore] = useState(true)

  const client = useApolloClient()

  const loadMore = async page => {
    const { data } = await client.query({
      query: GET_CONTENT,
      variables: {
        skip: (page - 1) * NB_ITEMS,
        limit: NB_ITEMS,
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    })
    const newWritings = [
      ...(data.me.accountOwnerships || []).map(o =>
        (o.zookeeper.writings || []).map(w => ({
          ...w,
          zookeeper: o.zookeeper,
        }))),
      ...(data.me.children
      .map(c =>
        c.writings.map(w => ({
          ...w,
          zookeeper: { _id: c._id, username: c.username, avatar: c.avatar, nickname: c.nickname },
        }))
      ) || [])]
      .flat()
      .sort((a, b) => b.createdAt - a.createdAt)
    setWritings([...writings, ...newWritings])
    if (newWritings.length < NB_ITEMS) {
      setHasMore(false)
    }
  }

  return { writings, loadMore, hasMore }
}
